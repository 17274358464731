import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Hint6 = () => (
  <Layout>
    <Header /> 

    <header className="masthead7">
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Hint #6</h1>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>           
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            This beacon of strength
            <br></br>
            cannot be broken.
            <br></br>
            <br></br>
            Keeping family history
            <br></br>
            remembered and spoken.
          </h2>
          <br></br>
          <br></br>
          <h3 className="roll-in-bottom mx-auto">
          Distance: 3 blocks North</h3>
          <br></br>
          <a href="/clue6" className="btn btn-primary">
              Back to Clue
            </a>
            <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br> 
          <br></br>
        </div>
      </div>
    </header>

    {/* <Clues />  

    <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Hint6;
